<template>
    <b-table v-if="tableData" hover :items="tableData" responsive :fields="fields" class="mb-0">
      <!-- id -->
      <template #cell(id)="data">
        <div class="font-weight-bolder">
          {{ data.item.id }}
        </div>
      </template>

      <!-- name -->
      <template #cell(name)="data">
        <router-link :to="'/exercise/'+data.item.id">{{ data.item.name }}</router-link>
      </template>

      <!-- actions -->
      <template #cell(actions)="data">

        <router-link :to="'/exercise/'+data.item.id">
          <feather-icon
            icon="EditIcon"
            class="align-middle text-body cursor-pointer"
            size="16"
          />
        </router-link>
        
          <feather-icon
            icon="TrashIcon"
            class="align-middle text-body cursor-pointer"
            size="16"
            style="margin-left:1em;"
            @click="confirmDelete(data.item)"
          />
        
        <!-- 
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item>
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown> -->
      </template>
    </b-table>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BImg,
  BDropdown,
  BTooltip,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  emits:['reload'],
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BDropdown,
    BTooltip,
    BDropdownItem,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    active: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fields: [
        { key: "id", label: "ID" },
        { key: "name", label: "NAME" },
        { key: "actions", label: "ACTIONS" },
      ],
    };
  },
  methods: {
    confirmDelete(item) {
      if (item.rulecount) {
          if (confirm('This exercise is used in '+item.rulecount+' triagerule(s). Are you sure you want to delete this exercise?')) {
            this.delete(item);
          }
        }
        else {
          if (confirm('Are you sure you want to delete this exercise?')) {
            this.delete(item);
          }
      }
    },
    async delete(item) {
        console.log('delete', item);
        var res = await this.callApi('delete', '/exercises/'+item.id);
        this.$emit('reload')
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
